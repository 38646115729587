import { PrimaryButton, SecondaryButton, Select } from '@/components';
import { createPart } from '@/redux/states/lessons/parts.slice';
import { useDispatch, useSelector } from 'react-redux';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { AppStore } from '@/redux';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';

const defaultValuesAdapter = (data: any) => {
  return {
    incorrecto: data?.redirections?.[0]?.next_part_slug ?? 'default',
    correcto: data?.redirections?.[1]?.next_part_slug ?? 'default'
  };
};

interface RedirectionProps {
  close: () => void;
}

export const Redirection: React.FC<RedirectionProps> = ({ close }) => {
  const dispatch = useDispatch();

  const { part, parts } = useSelector((state: AppStore) => state.parts);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { ...defaultValuesAdapter(part) }
  });

  const isQuiz = !!part?.quiz;

  const onSubmit = async (data: any) => {
    const correcto = data?.correcto !== 'default' ? data.correcto : undefined;
    const incorrecto = data?.incorrecto !== 'default' ? data.incorrecto : undefined;

    const redirections =
      parts.length > 0
        ? [
            {
              title: parts?.find(({ slug }: { slug: string }) => slug === data?.incorrecto)?.title ?? undefined,
              next_part_slug: incorrecto
            },
            {
              title: parts?.find(({ slug }: { slug: string }) => slug === correcto)?.title ?? undefined,
              next_part_slug: correcto
            }
          ].filter(({ next_part_slug }: { next_part_slug: string }) => {
            return next_part_slug;
          })
        : [];
    dispatch(
      createPart({
        ...(part || {}),
        redirections: isQuiz ? redirections : [redirections?.[0]]
      })
    );
    close();
  };

  const closeModal = (e: React.FormEvent) => {
    e.preventDefault();
    close();
  };

  const handleDelete = (name: 'incorrecto' | 'correcto') => {
    setValue(name, undefined);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {true ? (
        <div>
          {isQuiz ? (
            <div className='p-4 w-5/6'>
              <TitleInput className=''>{`${t('pages.lessons.nextStep')} - ${t(
                'pages.lessons.correctAnswer'
              )}`}</TitleInput>
              <div className='flex justify-center items-center'>
                <Select
                  className='p-4 w-full'
                  options={parts}
                  optionmap={(value) => value.title}
                  optionValueMap={(value) => value.slug}
                  optionKeyMap={(value) => value.id}
                  register={() => register('correcto')}
                />
                <span
                  onClick={(e: React.FormEvent) => {
                    e.preventDefault();
                    handleDelete('correcto');
                  }}
                  className='material-symbols-outlined cursor-pointer'>
                  close
                </span>
              </div>
            </div>
          ) : null}
          <div className='p-4 w-5/6'>
            <TitleInput className=''>{`${t('pages.lessons.nextStep')} ${
              isQuiz ? `- ${t('pages.lessons.incorrectAnswer')}` : ''
            }`}</TitleInput>
            <div className='flex justify-center items-center'>
              <Select
                className='p-1 w-full'
                options={parts}
                optionmap={(value) => value.title}
                optionValueMap={(value) => value.slug}
                optionKeyMap={(value) => value.id}
                register={() => register('incorrecto')}
              />
              <span
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  handleDelete('incorrecto');
                }}
                className='material-symbols-outlined cursor-pointer'>
                close
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <div className=' flex justify-end'>
        <PrimaryButton type='submit'>{t('pages.lessons.saveRedirections')}</PrimaryButton>
        <SecondaryButton className='px-8' onClick={closeModal}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </form>
  );
};
