import { createElement } from "react"
import cn from "classnames"

import { CreateArea } from "@/pages/empresas/modals/CreateArea.modal"
import { ChooseVideo } from "@/pages/lessons/modals/ChooseVideo.modal"
import { FormQuestions } from "@/pages/lessons/modals/formQuestions.modal"
import { ChoosePlay } from "@/pages/lessons/modals/choosePlay.modal"
import { Redirection } from "@/pages/lessons/modals/Redirection.modal"

const NotFound: React.FC = () => <div>Loading...</div>
// Modals list:
const Modals: any = {
    CreateArea,
    ChooseVideo,
    NotFound,
    FormQuestions,
    ChoosePlay,
    Redirection
}


type NamesModal = "CreateArea" | "NotFound" | 'ChooseVideo' | 'FormQuestions' | 'ChoosePlay' | 'Redirection'

type ModalProps = {
    name: NamesModal
    close: () => void
    data: any
    title: string
    size: string | boolean
}

export const Modal: React.FC<ModalProps> = ({ name, close, data, title, size = false }) => {
    return <dialog id="my_modal_1" className={cn("modal", { 'modal-open': name })}>
        <div className={cn("modal-box", { 'max-w-none': size }, size)}>
            <div className="flex justify-between mb-5">
                <h3 className="font-bold text-2xl">{title}</h3>
                <span onClick={close} className="material-symbols-outlined cursor-pointer">close</span>
            </div>
            {name ? createElement(Modals?.[name] ?? NotFound, { ...data, close }) : null}
        </div>
    </dialog>
}
