import { Area } from "@/models/areas.models"

export const loadAreaAdapter = (area: Area) => {
    return  ({
        licenseActive: area.license_active,
        name: area.name,
        id: area.id,
        info: area.info,
        isActive: area.is_active,
        expectedUsers: area.expected_users
    })
}
