import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { AppStore } from '@/redux';
import { useSelector } from 'react-redux';

import { useAuthStore } from '@/pages/auth/hooks';
import { DropdownIcon, LanguageModal } from '..';
import { IoLanguage } from 'react-icons/io5';
import { useState } from 'react';
import { t } from 'i18next';

const iconsMap = (className: string) => [
   {
      link: '/dashboard',
      icon: <span className={cn('material-symbols-outlined', className)}>space_dashboard</span>,
      message: 'Dashboard'
   },
   {
      link: '/empresas',
      icon: <span className={cn('material-symbols-outlined', className)}>apartment</span>,
      message: t('generics.companies')
   },
   {
      withCompanySelected: true,
      link: '/modulos',
      icon: <span className={cn('material-symbols-outlined', className)}>extension</span>,
      message: t('generics.modules')
   },
   {
      withCompanySelected: true,
      link: '/lecciones',
      icon: <span className={cn('material-symbols-outlined', className)}>smart_display</span>,
      message: t('generics.lessons')
   },
   {
      withCompanySelected: true,
      icon: <span className={cn('material-symbols-outlined', className)}>store</span>,
      message: t('generics.store')
   },
   {
      withCompanySelected: true,
      link: '/usuarios',
      icon: <span className={cn('material-symbols-outlined', className)}>person</span>,
      message: t('generics.users')
   },
   {
      withCompanySelected: true,
      link: '/premios',
      icon: <span className={cn('material-symbols-outlined', className)}>rewarded_ads</span>,
      message: t('generics.awards')
   },
   {
      withCompanySelected: true,
      icon: <span className={cn('material-symbols-outlined', className)}>settings</span>,
      message: t('generics.configuration')
   },
   {
      isSuperAdmin: true,
      link: '/licencias',
      icon: <span className={cn('material-symbols-outlined', className)}>receipt_long</span>,
      message: t('generics.licenses')
   }
];

interface SideBarProps {
   onClickMenu: () => void;
}
export const Sidebar: React.FC<SideBarProps> = ({ onClickMenu }) => {
   const { startLogout } = useAuthStore();

   const optionsMenu = [
      { message: t('generics.profile'), link: '/perfil', id: 2 },
      {
         message: t('generics.closeSession'),
         onclick: () => {
            startLogout();
         },
         id: 1
      }
   ];
   const { user, companySlug } = useSelector((state: AppStore) => ({
      ...state.auth,
      ...state.ui
   }));

   const rolDefault = 'Súper Administrador';

   const [showLanguageModal, setShowLanguageModal] = useState<boolean>(false);

   const iconsMapConf = (className: string) => [
      {
         icon: <span className={cn('material-symbols-outlined', className)}>developer_guide</span>,
         message: t('generics.userManual')
      },
      {
         link: '/terminos-condiciones',
         icon: <span className={cn('material-symbols-outlined', className)}>assignment</span>,
         message: t('generics.termsConditions')
      },
      {
         icon: <IoLanguage className='text-primary-l-m text-[22px]' />,
         callback: () => {
            setShowLanguageModal(true);
         },
         message: t('language.label')
      }
   ];

   // TODO: VALIDACIÓN DE LA EMPRESA EXISTENTE

   return (
      <nav className='bg-[#000000] h-screen w-96 overflow-y-auto'>
         <div className='flex justify-between h-20 items-center px-4'>
            <img src='/logo/simon_logo-07.png' width='100px' height='20px' />
            <div className='flex'>
               <span className='material-symbols-outlined text-primary-l-m text-4xl mx-2'>notifications</span>
               <DropdownIcon icon={'account_circle'} options={optionsMenu} />
            </div>
         </div>
         <div className='h-56 flex items-center justify-center flex-col'>
            <img src={user?.avatar_path ?? '/usuario.png'} alt='' className='h-24 w-24 rounded-full' />
            <p className='font-bold text-xl text-primary-l-m'>{user?.name ?? rolDefault}</p>
         </div>
         <div className='mx-14'>
            <p className='font-semibold text-lg text-primary-l-m'>{t('generics.mainMenu')}</p>
            <p className='text-functional-el-l text-sm'>{t('others.sideBarMessage')}</p>
            <div className='my-5'>
               {iconsMap('text-primary-l-m').map(({ icon, message, link, isSuperAdmin, withCompanySelected }) => {
                  const isSuperAdminUser = user?.roles.find((rol: { name: string }) => rol.name === 'super_admin');
                  const show = (isSuperAdmin ? isSuperAdminUser : true) && (withCompanySelected ? companySlug : true);
                  if (!show) return null;
                  if (link)
                     return (
                        <NavLink className='flex my-4' key={message} onClick={onClickMenu} to={link}>
                           {icon} <p className='text-functional-l-l mx-3'>{message}</p>
                        </NavLink>
                     );
                  return (
                     <div key={message} className='flex my-4 items-center'>
                        {icon}
                        <p className='text-functional-l-l mx-3'>{message}</p>
                     </div>
                  );
               })}
               <img src='/lineas/simon_plataforma-11.svg' width={'50%'} />
               {iconsMapConf('h-5 w-5 text-primary-l-m').map(({ icon, message, link, callback }) =>
                  !link ? (
                     <div key={message} onClick={callback ? callback : () => {}} className='flex my-4 cursor-pointer'>
                        {icon}
                        <p className='text-functional-l-l mx-3'>{message}</p> :
                     </div>
                  ) : (
                     <NavLink className='flex my-4' key={message} onClick={onClickMenu} to={link}>
                        {icon}
                        <p className='text-functional-l-l mx-3'>{message}</p>
                     </NavLink>
                  )
               )}
            </div>
         </div>
         {showLanguageModal && <LanguageModal closeClick={setShowLanguageModal} />}
      </nav>
   );
};
