import { createPart, resetPart, setIsLoadingSavePart, setStatus } from "@/redux/states/lessons/parts.slice"
import { useDispatch, useSelector } from "react-redux"
import { quizInitialState } from "../models/quiz.model"
import toast from "react-hot-toast"
import { Part } from "../models/parts.model"
import { AppStore } from "@/redux"
import useAxiosPrivate from "@/hooks/useApiV2Private"

interface CreateInitialPart {
    type:string
    url_video:string
    name:string
    description:string
    options:number
    play_id?:boolean
    quiz?:boolean,
    slug?:string
}

export const useCrudParts = (lessonSlug:string,) => {

    const apiV2 = useAxiosPrivate()

    const dispatch = useDispatch()

    const { part} = useSelector((state: AppStore) => state.parts)

    const createNewPart = (data:CreateInitialPart) => {
        dispatch(createPart({...data}))
    }

    const savePart = async (data:CreateInitialPart) => {
        dispatch(setIsLoadingSavePart(true))
        try {
            await apiV2.post(`/parts`,{
                ...data, 
                quiz: data.quiz ? quizInitialState : null, 
                play_id: data.play_id ? -1 : null,
                is_active:true,
                lesson_slug:lessonSlug,
                order:1
                
            })
            toast.success('Paso creado')
        } catch (error) {
            toast.error('Error en el servidor')
        } finally {
            dispatch(setIsLoadingSavePart(true))
        }
    }

    const editPart = async (data:CreateInitialPart) => {
        dispatch(setIsLoadingSavePart(true))
        try {
            await apiV2.patch(`/parts/${data.slug}`,{
                ...data,
                lesson_slug:lessonSlug
            })
            toast.success('Paso actualizado')
            dispatch(setStatus('viewing'))
        } catch (error) {
            toast.error('Error al actulizar paso')
        } finally {
            dispatch(setIsLoadingSavePart(true))
        }
    }

    const changeStatus = (status:string) => {
        dispatch(setStatus(status))
    }

    const changeStatusViewing = () => {
        dispatch(resetPart())
    }

    const deleteQuiz = () => {
        dispatch(createPart({
            ...part,
            quiz: null,
          }))
    }

    const deleteReward = () => {
        dispatch(createPart({
            ...part,
            play_id: null,
            play:{name:undefined}
          }))
    }

    const saveEditedPart = async (part:Part) =>{
        dispatch(setIsLoadingSavePart(true))
        try {
            await apiV2.patch(`/parts/${part.slug}`, {
                ...part,
                lesson_slug:lessonSlug,
            })
            dispatch(resetPart())
            toast.success('Paso actualizado')
        } catch (error) {
            dispatch(setIsLoadingSavePart(false))
        }
    }

    const saveCreatePart = async (data:Part) => {
        dispatch(setIsLoadingSavePart(true))
        delete data.play
        try {
            await apiV2.post(`/parts`,{
                ...data,
                is_active:true,
                lesson_slug:lessonSlug,
                order:1
            })
            dispatch(resetPart())
            toast.success('Paso creado')
        } catch (error) {
            toast.error('Error en el servidor')
        } finally {
            dispatch(setIsLoadingSavePart(true))
        }
    }

    

    return {
        changeStatusViewing ,
        saveCreatePart , 
        saveEditedPart ,
        deleteReward, 
        createPart, 
        editPart,
        changeStatus, 
        createNewPart, 
        savePart, 
        deleteQuiz
    }

}
