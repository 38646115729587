import { PrimaryButton, SecondaryButton } from '@/components';
import { InputText } from '@/components/Inputs/InputText';
import { TextArea } from '@/components/Inputs/TextArea';
import { SmallSpinner } from '@/components/Spinners/Spinner';
import { useLoadAreas } from '@/hooks/useLoadAreas';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface AreaFormProps {
  desc: string;
  name: string;
  expectedUsers: number;
}

interface CreateAreaProps {
  save: () => void;
  close: () => void;
  companySlug: string;
  idArea?: string;
}

export const CreateArea: React.FC<CreateAreaProps> = ({ close, companySlug, idArea }) => {
  const { createArea, getArea, updateArea, isLoadingArea } = useLoadAreas(companySlug);

  const onSubmit = async (data: any) => {
    const resp = idArea ? updateArea(data) : createArea(data);
    resp.then((e) => {
      if (e === 'success') return close();
    });
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    close();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<AreaFormProps>();

  const loadAreaDefault = async (idArea: string) => {
    const resp = await getArea(idArea);
    const { name, info, expectedUsers } = resp?.data ?? { name: '', desc: '', expectedUsers: 0 };
    setValue('name', name ?? '');
    setValue('desc', info?.desc ?? '');
    setValue('expectedUsers', expectedUsers ?? 0);
  };

  useEffect(() => {
    if (idArea) {
      loadAreaDefault(idArea);
    }
  }, [idArea]);

  return (
    <>
      {!isLoadingArea ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            className='w-5/6'
            placeholder={t('generics.name')}
            register={() => register('name', { required: t('errorMessage.requiredField') })}
          />
          {errors.name ? <p className='text-warning-d'>{errors.name.message}</p> : null}
          <TextArea
            placeholder={t('generics.description')}
            register={() => register('desc', { required: t('errorMessage.requiredField') })}
          />
          {errors.desc ? <p className='text-warning-d'>{errors.desc.message}</p> : null}
          <InputText
            className='w-5/6'
            placeholder={t('generics.expectedUsers')}
            type='number'
            register={() => register('expectedUsers', { required: t('errorMessage.requiredField') })}
          />
          {errors.expectedUsers ? <p className='text-warning-d'>{errors.expectedUsers.message}</p> : null}

          <div className='modal-action'>
            {<PrimaryButton type='submit'>{t('generics.accept')}</PrimaryButton>}
            {
              <SecondaryButton className='px-5' onClick={handleCancel}>
                {t('generics.cancel')}
              </SecondaryButton>
            }
          </div>
        </form>
      ) : (
        <SmallSpinner />
      )}
    </>
  );
};
