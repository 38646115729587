import { useEffect, useState } from 'react';
import { IconPlusButton, PrimaryButton, SecondaryButton, Select } from '@/components';
import { InputText } from '@/components/Inputs/InputText';
import { TextArea } from '@/components/Inputs/TextArea';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { useModal } from '@/hooks/useModal';
import { AppStore } from '@/redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useCrudParts } from '../hooks/useCrudParts';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { usePlays } from '../hooks';

interface FormPartEditProps {
  lessonSlug: string;
}

interface UseFormProp {
  type: string;
  description?: string;
  title: string;
  require_auth: boolean;
  is_practical_activity: boolean;
  practical_activity_title: string;
  practical_activity_play_id: number;
}

const messageRequired = `${t('errorMessage.requiredField')} `;

export const FormPartEdit: React.FC<FormPartEditProps> = ({ lessonSlug }) => {
  const { videoSelected, status, duration, videoIncorrectSelected, part, parts } = useSelector(
    (state: AppStore) => state.parts
  );
  const isEditing = status === 'editing';
  const isCreating = status === 'creating';

  const [disabledButton, setDisabledButton] = useState(false);

  const [isReward, setIsReward] = useState<boolean>(!!part?.play_id);
  const [isQuiz, setIsQuiz] = useState<boolean>(!!part?.quiz);
  const [isPracticalActivity, setIsPracticalActivity] = useState<boolean>(false);
  const [practicalActivityPlayId, setPracticalActivityPlayId] = useState<number | null>(null);
  const [practicalActivityTitle, setPracticalActivityTitle] = useState<string>('');
  const { deleteQuiz, deleteReward, saveEditedPart, saveCreatePart, changeStatusViewing } = useCrudParts(lessonSlug);

  // const fileId = 17352512

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<UseFormProp>();

  const { startShowModal } = useModal();
  const { loadPlays, plays } = usePlays();

  const onSubmit = async (data: any) => {
    setDisabledButton(true);
    if (isPracticalActivity && !practicalActivityTitle) {
      toast.error('El título de la actividad práctica es requerido.');
      setDisabledButton(false);
      return;
    }
    if (isEditing) {
      if (videoSelected || part?.video_url) {
        await saveEditedPart({
          ...part,
          ...data,
          ...videoSelected.qualityVideos,
          video_url: videoSelected.link || part?.video_url,
          is_practical_activity: isPracticalActivity,
          practical_activity_play_id: practicalActivityPlayId,
          practical_activity_title: practicalActivityTitle,
          duration,
          redirections: part.redirections ?? [],
          quiz: isQuiz
            ? {
                ...part?.quiz,
                not_correct_video_url: videoIncorrectSelected || part?.quiz?.not_correct_video_url
              }
            : null
        });
      } else {
        toast.error(t('pages.lessons.requestedVideos'));
      }
    } else if (isCreating) {      
      if (videoSelected && (isQuiz ? videoIncorrectSelected : true)) {
        await saveCreatePart({
          ...part,
          ...data,
          is_practical_activity: isPracticalActivity,
          practical_activity_play_id: practicalActivityPlayId,
          practical_activity_title: practicalActivityTitle,
          ...videoSelected.qualityVideos,
          video_url: videoSelected.link || part?.video_url,
          duration,
          redirections: part?.redirections ?? [],
          quiz: isQuiz
            ? {
                ...part?.quiz,
                not_correct_video_url: videoIncorrectSelected
              }
            : null,
          is_root: parts.length === 1
        });
      } else {
        toast.error(t('pages.lessons.requestedVideos'));
      }
    }
    setDisabledButton(false);
  };

  const handleChooseVideo = (e: React.FormEvent) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectVideo'),
      name: 'ChooseVideo',
      data: { label: 'videoSelected' },
      size: 'w-11/12'
    });
  };

  const handleChooseWrongVideo = (e: React.FormEvent) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectVideo'),
      name: 'ChooseVideo',
      data: { label: 'videoIncorrectSelected' },
      size: 'w-11/12'
    });
  };

  useEffect(() => {
    if (isPracticalActivity) {
      loadPlays();
    }  
  }, [isPracticalActivity])

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    changeStatusViewing();
  };

  const handleRedirection = (e: React.FormEvent) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.redirections'),
      name: 'Redirection'
    });
  };

  const handleEditQuestion = (e: any) => {
    e.preventDefault();

    if (e.target.checked) {
      startShowModal({
        title: t('pages.lessons.questionForm'),
        name: 'FormQuestions'
      });
    } else {
      deleteQuiz();
    }
  };

  const handleChangeQuestion = (e: any) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.questionForm'),
      name: 'FormQuestions'
    });
  };

  const handleChangeReward = (e: any) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectAward'),
      name: 'ChoosePlay'
    });
  };

  const handleEditReward = (e: any) => {
    e.preventDefault();
    if (e.target.checked) {
      startShowModal({
        title: t('pages.lessons.selectAward'),
        name: 'ChoosePlay'
      });
    } else {
      deleteReward();
    }
  };

  const partSlug = part?.slug;

  useEffect(() => {
    // loadLocalPart(part)
    setValue('type', part?.type);
    setValue('title', part?.title);
    setValue('description', part?.description);
    setValue('require_auth', part?.require_auth ?? false);
    setIsPracticalActivity(!!part?.is_practical_activity);  
    setPracticalActivityTitle(part?.practical_activity_title ?? '');
    setPracticalActivityPlayId(part?.practical_activity_play_id ?? null);
  }, [lessonSlug, partSlug]);

  useEffect(() => {
    setIsReward(!!part?.play_id);
  }, [part?.play_id]);

  useEffect(() => {
    setIsQuiz(!!part?.quiz);
  }, [part?.quiz]);

  useEffect(() => {
    setIsPracticalActivity(!!part?.is_practical_activity);
  }, [part?.is_practical_activity]);

  useEffect(() => {
    setPracticalActivityTitle(part?.practical_activity_title ?? '');
    setPracticalActivityPlayId(part?.practical_activity_play_id ?? null);
  }, [part?.practical_activity_title, part?.practical_activity_play_id]);

  const disabled = false;

  console.log(videoSelected, videoIncorrectSelected);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p title={isEditing ? part.title : t('generics.new')} className=' text-4xl font-bold line-clamp-2'>{`${t(
        'pages.lessons.configStep'
      )} ${isEditing ? part.title : t('generics.new')}`}</p>
      <div className='p-4 w-5/6'>
        <TitleInput>{`${t('pages.lessons.stepType')}*`}</TitleInput>
        <Select
          className='w-full mt-4'
          defaultValue={'video'}
          options={['video']}
          optionmap={(value) => value}
          register={() => register('type', { required: messageRequired })}
        />
        {errors.type ? <p className='text-warning-d'>{errors.type.message}</p> : null}
      </div>
      <div className='p-4 w-5/6'>
        <TitleInput>Video*</TitleInput>
        <div className='flex flex-col items-center justify-center'>
          <div className={classNames('w-full my-2 h-[200px] row-span-2')}>
            {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
            {/* <img src={image?.image_path ?? "/imgLayout.png"} alt="" className="row-span-2 w-full h-full object-contain" /> */}
            {videoSelected ? (
              <video
                className='row-span-2 w-full h-full object-contain'
                src={typeof videoSelected === 'string' ? videoSelected : videoSelected.link}></video>
            ) : (
              <img src={'/imgLayout.png'} alt='' className='row-span-2 w-full h-full object-contain' />
            )}
          </div>
          {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
          <IconPlusButton onClick={handleChooseVideo} className='w-full'>
            {t('pages.lessons.selectVideo2')}
          </IconPlusButton>
        </div>
      </div>
      <div className='p-4 w-5/6'>
        <InputText
          className='w-full'
          disabled={disabled}
          register={() => register('title', { required: messageRequired })}
          placeholder={`${t('generics.title')}*`}
        />
        {errors.title ? <p className='text-warning-d'>{errors.title.message}</p> : null}
      </div>
      <div className='p-4 w-5/6'>
        <TextArea
          className='w-full'
          disabled={disabled}
          register={() => register('description')}
          placeholder={t('generics.description')}
        />
        {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
      </div>
      <div className='p-4 mt-4 w-5/6'>
        <TitleInput>{t('pages.lessons.selectTypeOptions')}</TitleInput>
        <div className='flex justify-between items-center my-2'>
          <TitleInput>Quiz</TitleInput>
          <div className='flex items-center'>
            {isQuiz ? (
              <button onClick={handleChangeQuestion} className='justify-self-end mx-4'>
                <span className='material-symbols-outlined'>edit_square</span>
              </button>
            ) : null}
            <ToggleSwitch onChange={handleEditQuestion} checked={isQuiz} message='' />
          </div>
        </div>
        <hr />
        {isQuiz ? (
          <div className='my-4 '>
            <div className='flex flex-col items-center justify-center'>
              <div className={classNames('w-full my-2 h-[200px] row-span-2')}>
                {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                {/* <img src={image?.image_path ?? "/imgLayout.png"} alt="" className="row-span-2 w-full h-full object-contain" /> */}
                {videoIncorrectSelected ? (
                  <video
                    className='row-span-2 w-full h-full object-contain'
                    src={
                      typeof videoIncorrectSelected === 'string' ? videoIncorrectSelected : videoIncorrectSelected.link
                    }></video>
                ) : (
                  <img src={'/imgLayout.png'} alt='' className='row-span-2 w-full h-full object-contain' />
                )}
              </div>
              {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
              <IconPlusButton onClick={handleChooseWrongVideo} className='w-full'>
                {t('pages.lessons.incorrectVideo')}
              </IconPlusButton>
            </div>
          </div>
        ) : null}
        {/* <label className="label cursor-pointer "> */}
        {/* <span className="label-text font-bold text-lg">Recompensa</span> */}
        {/* <input type="checkbox" className="checkbox" {...register('isReward')} checked={options?.isReward} /> */}
        {/* <input type="checkbox" className="checkbox" {...register('isReward', {
                        onChange: (e: any) => {
                            handleEditReward(e)
                        }
                    })} /> */}
        {/* <input type="checkbox" className="checkbox" onChange={handleEditReward} checked={isReward} /> */}
        {/* </label> */}
        <div className='flex justify-between items-center my-4'>
          <TitleInput>{t('generics.award')}</TitleInput>
          <div className='flex items-center'>
            {isReward ? (
              <button onClick={handleChangeReward} className='justify-self-end mx-4'>
                <span className='material-symbols-outlined'>edit_square</span>
              </button>
            ) : null}
            <ToggleSwitch onChange={handleEditReward} checked={isReward} message='' />
          </div>
        </div>
        <hr />
        {isReward ? <p className='text-xl my-3'>{`${t('pages.lessons.selectedAward')}: ${part?.play?.name}`}</p> : null}
        <div className='my-4'>
          <p className='py-4 text-xl font-bold'>{`${t('pages.lessons.nextStep')} ${
            isQuiz ? `(${t('pages.lessons.incorrectAnswer')}) :` : ':'
          }`}</p>
          <p className='py-4 text-xl '> {part?.redirections?.[0]?.title || ''}</p>
          {isQuiz ? (
            <>
              <p className='py-4 text-xl font-bold'>{`${t('pages.lessons.nextStep')} (${t(
                'pages.lessons.correctAnswer'
              )}):`}</p>
              <p className='py-4 text-xl '> {part?.redirections?.[1]?.title || ''}</p>
            </>
          ) : null}
          <IconPlusButton onClick={handleRedirection} className='w-full my-4'>
            {t('pages.lessons.selectNextStep')}
          </IconPlusButton>
        </div>
        <div className='flex justify-between items-center my-4'>
          <TitleInput>{t('pages.lessons.RequiresAuthentication')}</TitleInput>
          <ToggleSwitch register={() => register('require_auth')} message='' />
        </div>
        {/* Practical Activity */}
        <div className='flex justify-between items-center my-4'>
          <TitleInput>{t('pages.lessons.practicalActivity')}</TitleInput>
          <ToggleSwitch checked={isPracticalActivity} onChange={(e) => {
            const value = e.target.checked;
            setIsPracticalActivity(value);
          }} message=''/>
        </div>
        {
          isPracticalActivity && (
            <>
              <InputText
                className='w-full'
                disabled={disabled}
                onChange={(e) => setPracticalActivityTitle(e.target.value)}
                value={practicalActivityTitle}
                placeholder={`${t('pages.lessons.titlePractical')}*`}
              />

              <div className='flex justify-between items-center my-2'>
                <TitleInput>{t('generics.award')}</TitleInput>
              </div>
              {
                plays && (
                  <Select
                    className='w-full'
                    defaultValue={part?.practical_activity_play_id}
                    options={plays}
                    optionmap={(play) => play.name}
                    optionValueMap={(play) => play.id}
                    onChange={(e) => {
                      setPracticalActivityPlayId(Number(e.target.value))
                    }}
                  />
                )
              }
            </>
          )
        }
      </div>
      <div className='flex justify-end'>
        <PrimaryButton disabled={disabledButton} type='submit'>
          {t('generics.save')}
        </PrimaryButton>
        <SecondaryButton className='px-5' onClick={handleCancel}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </form>
  );
};
